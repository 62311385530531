
.submission-status {
  margin-left: 8px;
}

.contact-additional {
  margin-bottom: 24px;
  line-height: 2;

  a { text-decoration: underline; }

  h4 {
    text-align: center;
    margin: 16px;
  }
}
